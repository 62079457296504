<template>
  <div class="page-team">
    <div class="team-flex-container">
      <div class="team-flex-inner">
        <img src="/assets/team/christine.png" class="team-profile-picture" />
        <h2 class="team-flex-title">Christine Gouverneur</h2>
        <h2 class="team-flex-subtitle">{{ $t("team-job-2") }}</h2>
      </div>
      <div class="team-flex-inner">
        <h2 class="team-flex-title">{{ $t("team-contact") }}</h2>
        <br />
        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/mail.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">christine.gouverneur@youth.lu</div>
        </div>
        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/phone.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">621 629 266</div>
        </div>

        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/clock.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">{{ $t("team-timing-christine") }}</div>
        </div>
      </div>
      <div class="team-flex-inner">
        <h2 class="team-flex-title">{{ $t("team-sections") }}</h2>
        <br />
        <ul class="team-flex-list">
          <li>{{ $t("team-text-christine-1") }}</li>
          <li>{{ $t("team-text-christine-2") }}</li>
          <li>{{ $t("team-text-christine-3") }}</li>
          <li>{{ $t("team-text-christine-4") }}</li>
          <li>{{ $t("team-text-christine-5") }}</li>
          <li>{{ $t("team-text-christine-6") }}</li>
          <!--<li>{{ $t("team-text-christine-7") }}</li>-->
          <li>{{ $t("team-text-christine-8") }}</li>
        </ul>
      </div>
    </div>
    <div class="team-flex-container">
      <div class="team-flex-inner">
        <img src="/assets/team/jil.png" class="team-profile-picture" />
        <h2 class="team-flex-title">Jil Baustert</h2>
        <h2 class="team-flex-subtitle">{{ $t("team-job-3") }}</h2>
      </div>
      <div class="team-flex-inner">
        <h2 class="team-flex-title">{{ $t("team-contact") }}</h2>
        <br />
        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/mail.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">jil.baustert@youth.lu</div>
        </div>
        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/phone.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">621 629 264</div>
        </div>
        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/clock.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">{{ $t("team-timing-jil") }}</div>
        </div>
      </div>
      <div class="team-flex-inner">
        <h2 class="team-flex-title">{{ $t("team-sections") }}</h2>
        <br />
        <ul class="team-flex-list">
          <li>{{ $t("team-text-jil-1") }}</li>
          <li>{{ $t("team-text-jil-2") }}</li>
          <li>{{ $t("team-text-jil-3") }}</li>
          <li>{{ $t("team-text-jil-4") }}</li>
          <li>{{ $t("team-text-jil-5") }}</li>
        </ul>
      </div>
    </div>

    <div class="team-flex-container">
      <div class="team-flex-inner">
        <img src="/assets/team/nathalie.png" class="team-profile-picture" />
        <h2 class="team-flex-title">Nathalie Moris</h2>
        <h2 class="team-flex-subtitle">{{ $t("team-job-1") }}</h2>
      </div>
      <div class="team-flex-inner">
        <h2 class="team-flex-title">{{ $t("team-contact") }}</h2>
        <br />
        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/mail.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">nathalie.moris@youth.lu</div>
        </div>
        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/phone.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">621 629 261</div>
        </div>

        <div class="team-iconlist-container">
          <div class="team-iconlist-flex">
            <img src="/assets/icons/clock.svg" class="team-icon-picture" />
          </div>
          <div class="team-iconlist-flex">{{ $t("team-timing-astrid") }}</div>
        </div>
      </div>
      <div class="team-flex-inner">
        <h2 class="team-flex-title">{{ $t("team-sections") }}</h2>
        <br />
        <ul class="team-flex-list">
          <li>{{ $t("team-text-astrid-1") }}</li>
          <li>{{ $t("team-text-astrid-2") }}</li>
          <li>{{ $t("team-text-astrid-3") }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Team",
};
</script>

<style>
.page-team {
  width: auto;
  margin: 0 0 0 15vw;
  padding: 0;
  background-color: var(--charte-color-grey-1);
}

.team-flex-container {
  display: flex;
  padding: 2em;
}

.team-flex-inner {
  flex: 1;
  padding: 1em 2em 1em 2em;
}

.team-flex-inner:nth-child(1),
.team-flex-inner:nth-child(2) {
  flex: 1;
  border-right: 1px solid #3c3c3c20;
}

.team-flex-title {
  color: var(--charte-color-talent);

  font-family: "Meta Pro";
  font-weight: 400;
  font-size: 18pt;
  padding: 1em 0 0 0;
  margin: 0;
}

.team-flex-subtitle {
  color: var(--charte-color-grey-2);
  padding: 0;
  margin: 0;

  font-family: "Meta Pro";
  font-weight: 200;
  font-size: 14pt;
}

.team-profile-picture {
  height: 12em;
}

.team-iconlist-container {
  padding: 0.5em 0 0.5em 0;
  display: flex;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;
  color: var(--charte-color-grey-2);
}

.team-icon-picture {
  height: 1em;
  margin: 0 1em 0 0;
}

.team-flex-list {
  margin: 0;
  padding: 0;
}

.team-flex-list li {
  list-style-type: none;
  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 11pt;
  color: var(--charte-color-grey-2);
}

@media only screen and (max-width: 600px) {
  .team-flex-container {
    flex-direction: column;
    padding: 1em;
  }

  .team-flex-inner {
    border: none !important;
  }

  .page-team {
    margin: 0 10vw 0 10vw;
  }
}
</style>
