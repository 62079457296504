<template>
  <div class="merite-footer">
    <GradientContainer primaryColor="#DE0041" secondaryColor="#6B1F7C" angle="-20">
      <Container>
        <div class="merite-footer-flex-container">
          <div class="merite-footer-flex-child left">
            <div class="merite-footer-logo-container">
              <img src="/assets/logow.svg" class="merite-footer-logo" />
            </div>
            <div class="merite-footer-contact-container">
              <h2 class="merite-footer-contact-title">Mérite Jeunesse Luxembourg</h2>
              <p class="merite-footer-contact-information">
                20, rue de Contern<br />
                L-5955 Itzeg<br />
                E-Mail: merite@youth.lu<br />
              </p>
            </div>
          </div>
          <div class="merite-footer-flex-child right">
            <img src="/assets/duke.svg" class="merite-footer-duke-logo" />
          </div>
        </div>

        <div class="merite-footer-navigation-flex-container">
          <div class="merite-footer-navigation-flex-child">
            <h3>Get involved</h3>
            <ul>
              <router-link :to="{ name: 'participants' }"><li>Participants</li></router-link>
              <router-link :to="{ name: 'awardleaders' }"><li>Award Leaders</li></router-link>
              <router-link :to="{ name: 'awardunits' }"><li>Award Units</li></router-link>
              <router-link :to="{ name: 'supportus' }"><li>Sponsoring &amp; Support</li></router-link>
              <router-link :to="{ name: 'resourcecenter' }"><li>Resources &amp; Tools</li></router-link>
            </ul>
          </div>
          <div class="merite-footer-navigation-flex-child">
            <h3>News &amp; Agenda</h3>
            <ul>
              <router-link :to="{ name: 'agenda' }"><li>News</li></router-link>
              <router-link :to="{ name: 'agenda' }"><li>Agenda</li></router-link>
            </ul>
          </div>
          <div class="merite-footer-navigation-flex-child">
            <h3>Information</h3>
            <ul>
              <router-link :to="{ name: 'meritejeunesse' }"><li>About</li></router-link>
              <router-link :to="{ name: 'home' }"><li>Contact</li></router-link>
              <router-link :to="{ name: 'faq' }"><li>FAQ</li></router-link>
              <router-link :to="{ name: 'supportus' }"><li>Donate</li></router-link>
              <router-link :to="{ name: 'legal' }"><li>Legal Notice</li></router-link>
              <a href="https://www.onlinerecordbook.org/fo/" target="_blank"><li>ORB</li></a>
            </ul>
          </div>
        </div>
      </Container>

      <div class="merite-footer-copyright-container">
        <Container>
          <div class="merite-footer-copyright-flex-container">
            <div class="merite-footer-copyright-flex-child left">&copy; Mérite Jeunesse Luxembourg</div>
            <div class="merite-footer-copyright-flex-child right">
              Follow us:
              <a href="https://www.facebook.com/meritejeunesse" target="_blank"
                ><img src="/assets/icons/facebook.svg" class="merite-footer-social-icon"
              /></a>
              <a href="https://www.instagram.com/merite_jeunesse_lux/" target="_blank"
                ><img src="/assets/icons/instagram.svg" class="merite-footer-social-icon"
              /></a>
              <a href="https://lu.linkedin.com/company/m%C3%A9rite-jeunesse-luxembourg" target="_blank"
                ><img src="/assets/icons/linkedin.svg" class="merite-footer-social-icon"
              /></a>
            </div>
          </div>
        </Container>
      </div>
    </GradientContainer>
  </div>
</template>

<script>
import GradientContainer from "@/components/widgets/GradientContainer.vue";
import Container from "@/components/widgets/Container.vue";

export default {
  name: "Footer",
  props: {},
  components: {
    GradientContainer,
    Container,
  },
};
</script>

<style>
.merite-footer-copyright-container {
  width: 100%;
  height: 3.5em;

  display: flex;
  align-items: center;
  position: relative;
}

.merite-footer-copyright-container::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  background-color: var(--charte-color-talent);
  opacity: 0.25;
  mix-blend-mode: multiply;
}

.merite-footer-copyright-flex-container {
  display: flex;
  position: relative;
}

.merite-footer-copyright-flex-child {
  flex: 1;
  color: white;

  font-family: "Meta Pro";
  font-weight: 300;

  display: flex;

  align-content: center;
  justify-content: flex-start;
}

.merite-footer-copyright-flex-child.right {
  display: flex;

  align-content: center;
  justify-content: flex-end;
}

.merite-footer-flex-container {
  display: flex;
  border-bottom: 1px solid white;
}

.merite-footer-flex-child {
  flex: 1;
  display: flex;
}

.merite-footer-logo {
  margin: 4em 4em 1em 0;
  width: 5em;
}

.merite-footer-contact-container {
  padding: 4em 0 4em 0;
}

.merite-footer-contact-title {
  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;

  margin: 0;
  padding: 0;
}

.merite-footer-contact-information {
  margin: 1em 0 1em 0;

  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;
}

.merite-footer-duke-logo {
  height: 7em;

  margin: auto 0 auto 0;
}

.merite-footer-flex-child.right {
  display: flex;

  justify-content: flex-end;
  align-content: center;
}

.merite-footer-navigation-flex-container {
  display: flex;
  flex-wrap: wrap;

  margin: 2em 0 2em 0;
}

.merite-footer-navigation-flex-container {
  margin: 0 15em 0 0;
}

.merite-footer-navigation-flex-child {
  flex: 1;
}

.merite-footer-navigation-flex-child h3 {
  color: white;
  margin: 2em 0 1em 0;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 14pt;
}

.merite-footer-navigation-flex-child ul {
  margin: 0 0 4em 0;
  padding: 0;
}

.merite-footer-navigation-flex-child ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;

  color: white;

  font-family: "Meta Pro";
  font-weight: 300;
  font-size: 12pt;

  line-height: 20pt;
}

.merite-footer-social-icon {
  width: 1em;
  height: 1em;

  margin: 0 0.5em 0 0.5em;
}
.merite-footer-navigation-flex-child ul a {
  color: white;
  text-decoration: none;
}
.merite-footer-navigation-flex-child ul a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .merite-footer-flex-container {
    flex-direction: column;

    padding-bottom: 4em;

    align-items: center;
    justify-content: center;
  }

  .merite-footer-duke-logo {
    height: 4em;
  }

  .merite-footer-navigation-flex-container {
    margin: 0;
    flex-direction: column;
  }

  .merite-footer-navigation-flex-child ul {
    margin: 0 0 1em 0;
  }

  .merite-footer-copyright-container {
    flex-direction: column;
    height: auto;
  }

  .merite-footer-copyright-flex-container {
    flex-direction: column;
    height: auto;

    padding: 1em 0 1em 0;
  }

  .merite-footer-copyright-flex-child.right {
    align-content: flex-start;
    justify-content: flex-start;
    padding: 1em 0 0 0;
  }
}
</style>
