export default {
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr"])},
  "slider-merite-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une organisation de jeunesse internationale, ouvert pour toute personne âgée entre 14 et 25 ans."])},
  "slider-faq-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez trouver les questions les plus fréquemment posées en bas"])},
  "slider-home-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your\npassion,\n purpose and\n place in the world"])},
  "page-usefullinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens utiles"])},
  "home-get-engaged-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participer!"])},
  "home-get-involved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get involved"])},
  "home-get-involved-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre le Mérite Jeunesse signifie que vous vous associez au plus large mouvement international en faveur du développement de la jeunesse dans le monde"])},
  "home-latest-news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières nouvelles"])},
  "home-card-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "home-card-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aimerais-tu faire ton Mérite ? Ici tu trouveras toutes les informations !"])},
  "home-card-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leaders"])},
  "home-card-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aimerais-tu devenir tuteur/tutrice ? Ici tu trouveras toutes les informations !"])},
  "home-card-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Units"])},
  "home-card-text-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aimerais-tu que ton école/organisation rejoint le Mérite ? Ici tu trouveras toutes les informations !"])},
  "home-card-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring & Support"])},
  "home-card-text-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite Jeunesse soutient la jeunesse et nous avons besoin de votre aide pour pouvoir réaliser des projets et aider les jeunes à trouver leur sens, leur passion et leur place dans le monde."])},
  "home-card-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre de ressources"])},
  "home-card-text-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ici vous trouverez des documents importants et utiles."])},
  "home-agenda-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évènements à venir"])},
  "home-agenda-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ici vous trouverez notre agenda avec les dates les plus importantes"])},
  "meet-the-team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre équipe"])},
  "merite-in-numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mérite Jeunesse en chiffres"])},
  "merite-numers-active-participants-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1000"])},
  "merite-numers-active-participants-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants actifs par an"])},
  "merite-numers-active-tutors-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["140"])},
  "merite-numers-active-tutors-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuteurs"])},
  "merite-numers-units-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["41"])},
  "merite-numers-units-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités"])},
  "merite-numers-award-years-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31"])},
  "merite-numers-award-years-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["années du Mérite Jeunesse au Luxembourg"])},
  "agenda-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agenda"])},
  "agenda-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ici vous trouverez notre agenda avec les dates les plus importantes"])},
  "resources-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources Centre"])},
  "resources-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ici vous trouverez des documents importants et utiles"])},
  "participants-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "participants-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aimerais-tu faire ton Mérite ? Ici tu trouveras toutes les informations !"])},
  "participants-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi participer ?"])},
  "participants-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a beaucoup de bonnes raisons pour te lancer dans le programme"])},
  "participants-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du premier au dernier jour il s’agit d’une réelle aventure"])},
  "participants-paragraph-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu rencontreras de nouvelles personnes et feras l’expérience de travailler en équipe"])},
  "participants-paragraph-1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu développeras de nouvelles compétences et deviendras physiquement actif"])},
  "participants-paragraph-1-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu éprouveras du plaisir avec le programme que tu auras toi-même choisi"])},
  "participants-paragraph-1-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu feras beaucoup de nouvelles expériences"])},
  "participants-paragraph-1-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu te découvriras des talents insoupçonnés"])},
  "participants-paragraph-1-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu t’investiras dans des activités qui te passionnent et tu l’adoreras"])},
  "participants-paragraph-1-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu recevras un certificat reconnu dans le monde entier"])},
  "participants-paragraph-1-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relève le défi et rejoins le Mérite Jeunesse !"])},
  "participants-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment participer ?"])},
  "participants-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour t’inscrire afin de commencer ton Mérite, tu dois tout d’abord t’informer si ton lycée ou une organisation de jeunesse locale est une unité partenaire du Mérite Jeunesse Luxembourg."])},
  "participants-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans ton unité, tu choisis un tuteur qui t’aidera à définir tes activités et tes buts. Dès que tu as décidé quelles activités tu aimerais faire, tu signes le formulaire d’inscription et le remets à ton tuteur."])},
  "participants-paragraph-2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu n’es pas scolarisé dans une unité du Mérite ou que tu n’es pas membre d’une organisation partenaire du Mérite, tu peux contacter le bureau national et on trouvera une solution pour toi."])},
  "participants-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La structure du Mérite Jeunesse"])},
  "participants-paragraph-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme du Mérite Jeunesse t’aide à découvrir tes intérêts et talents. Il est structuré en différents volets et différents niveaux à accomplir. Tu peux choisir ton niveau et tes activités à ton gré."])},
  "participants-paragraph-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les trois niveaux :"])},
  "participants-framework-header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
  "participants-framework-header-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argent"])},
  "participants-framework-header-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
  "participants-framework-header-1-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge minimal: 14"])},
  "participants-framework-header-1-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée minimale: 6 mois"])},
  "participants-framework-header-2-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge minimal: 15"])},
  "participants-framework-header-2-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée minimale: 6 mois"])},
  "participants-framework-header-3-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge minimal: 16"])},
  "participants-framework-header-4-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée minimale: 12 mois"])},
  "participants-framework-header-1-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au niveau Bronze tu dois exercer une activité pendant 6 mois et les autres pendant 3 mois."])},
  "participants-framework-header-1-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’expédition dure 1 nuitée et 2 journées."])},
  "participants-framework-header-2-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu continues avec le niveau Argent, après d’avoir fini le niveau Bronze, tu dois compléter toutes tes activités pendant 6 mois."])},
  "participants-framework-header-2-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu te décides de commencer avec le niveau Argent, en tant qu’entrant direct, tu dois compléter une activité pendant 12 mois et les autres pendant 6 mois. L’expédition dure 2 nuitées et 3 journées."])},
  "participants-framework-header-3-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu continues avec le niveau Or, après d’avoir fini le niveau Argent, tu dois compléter tes activités pendant 12 mois."])},
  "participants-framework-header-3-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu te décides de commencer avec le niveau Or en tant qu’entrant direct, tu dois compléter une activité pendant 18 mois et les autres pendant 12 mois. L’expédition dure 3 nuitées et 4 journées."])},
  "participants-skill-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent"])},
  "participants-skill-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénévolat"])},
  "participants-skill-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
  "participants-skill-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
  "participants-skill-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet Résidentiel Gold "])},
  "participants-skill-description-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "participants-skill-description-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps à investir"])},
  "participants-skill-description-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemples"])},
  "participants-skill-1-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisis ou améliore un talent – il peut s’agir de tout, sauf d’un sport."])},
  "participants-skill-1-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Être actif pendant une heure chaque semaine"])},
  "participants-skill-1-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprendre ou améliorer la pratique d’un instrument"])},
  "participants-skill-1-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chanter"])},
  "participants-skill-1-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Théâtre"])},
  "participants-skill-1-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échecs"])},
  "participants-skill-1-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier secours"])},
  "participants-skill-1-items-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage d’escargots"])},
  "participants-skill-1-items-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrire des textes journalistiques"])},
  "participants-skill-2-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engage-toi dans la société sans être rémunéré et aide là où on a besoin d’aide. Si tu reçois une rémunération, tu dois faire un don avec cet argent."])},
  "participants-skill-2-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Être actif pendant une heure chaque semaine"])},
  "participants-skill-2-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aider des gens (Volontariat dans une crèche, dans une maison relais, dans un foyer de jour...)"])},
  "participants-skill-2-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ramassage de déchets"])},
  "participants-skill-2-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soigner des animaux (dans un asyle, comme dogsitter...)"])},
  "participants-skill-2-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aider de manière active dans une organisation comme Caritas, Croix-Rouge…"])},
  "participants-skill-2-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entraîner dans un sport ou d’autres activités (scouts …)"])},
  "participants-skill-3-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratique régulièrement un sport et améliore ton endurance et ta condition tout en trouvant une balance entre esprit, corps et âme."])},
  "participants-skill-3-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Être actif pendant une heure chaque semaine"])},
  "participants-skill-3-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute sorte de jeu de ballon"])},
  "participants-skill-3-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course à pieds"])},
  "participants-skill-3-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Golfe"])},
  "participants-skill-3-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danse"])},
  "participants-skill-3-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoga, Pilates"])},
  "participants-skill-3-items-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts martiaux"])},
  "participants-skill-4-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’expédition est le seul volet, qui doit être complété en équipe. Ce domaine vise à encourager ton esprit d’aventure et de découverte, tout en te sensibilisant pour la nature et l’environnement. Ce volet est complété en 4 étapes :"])},
  "participants-skill-4-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La durée de ton expédition dépend de ton niveau."])},
  "participants-skill-4-items-ol-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atelier de préparation"])},
  "participants-skill-4-items-ol-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition de préparation"])},
  "participants-skill-4-items-ol-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition finale"])},
  "participants-skill-4-items-ol-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise d’un Rapport à ton Évaluateur (Assessor)"])},
  "participants-skill-4-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À pied"])},
  "participants-skill-4-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À vélo"])},
  "participants-skill-4-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec canoë"])},
  "participants-skill-4-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À cheval"])},
  "participants-skill-5-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le projet Résidentiel Or est une activité avec un objectif claire et dans un environnement inconnu sans tes amis et ta famille."])},
  "participants-skill-5-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 4 nuitées et 5 journées"])},
  "participants-skill-5-items-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire un cours de langue à l’étranger"])},
  "participants-skill-5-items-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aider dans un camp d’été"])},
  "participants-skill-5-items-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["European Youth Parliament"])},
  "participants-skill-5-items-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un projet environnemental à l’étranger"])},
  "participants-skill-5-items-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..."])},
  "participants-framework-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque expédition doit être déclarée et acceptée au moins 3 semaines à l’avance au bureau national."])},
  "participants-framework-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque projet résidentiel doit être déclaré et accepté au moins 6 semaines à l’avance au bureau national."])},
  "participants-framework-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite Jeunesse se réserve le droit de refuser un projet résidentiel Or, si le projet ne semble pas approprié."])},
  "participants-framework-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite Jeunesse décline toute responsabilité pour les expéditions et projets résidentiels Or, non déclarés antérieurement."])},
  "participants-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations pour les parents"])},
  "participants-parents-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout ne s’apprend pas dans la salle de classe. Les jeunes ont besoin des expériences en dehors de l’école pour devenir des personnes engagées, responsables et satisfaites au sein de notre société."])},
  "participants-parents-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que structure d’éducation non-formelle, le Mérite Jeunesse peut jouer un rôle important dans la vie des adolescents en offrant des opportunités pour développer des compétences essentielles, augmenter leur employabilité et encourager leur créativité et leur innovation."])},
  "participants-parents-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite Jeunesse fait partie d’une organisation mondiale. Ce programme identique autour du monde est un défi personnel et peut être adapté aux intérêts de chaque participant. Il s’agit d’un programme balancé et entièrement volontaire, encourageant le développement individuel du jeune."])},
  "participants-parents-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvert à tout le monde"])},
  "participants-parents-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bon pour le CV"])},
  "participants-parents-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C’est un procès et pas un prix"])},
  "participants-parents-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme du Mérite Jeunesse est ouvert à toute personne âgée entre 14 et 24 ans, sans distinction de sexe, d’origine, de culture, de religion, de capacité physique et d’intérêts. Les participants reçoivent leur insigne grâce au progrès personnel, ce qui permet aussi aux jeunes à besoins spécifiques de participer."])},
  "participants-parents-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoir un insigne est une véritable plus-value sur le CV pour l’université, pour l’apprentissage et pour l’emploi. L’engagement dans le Mérite Jeunesse montre qu’on s’est engagé lors de son temps libre et qu’on a investi un certain temps dans les différentes activités. L’expérience acquise lors du programme, donne aussi la possibilité au jeune de parler de lui-même et de ses passions en dehors de l’école."])},
  "participants-parents-paragraph-2-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite est un processus de développement personnel et social et les activités des différents volets soutiennent ce procès. Par ces nouvelles expériences, les jeunes se découvrent de nouvelles compétences et des talents cachés."])},
  "participants-parents-paragraph-2-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre ambition est d’offrir à chaque jeune entre 14 et 24 ans la possibilité de participer au Mérite Jeunesse."])},
  "awardleaders-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Leaders"])},
  "awardleaders-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aimerais-tu devenir tuteur/tutrice ? Ici tu trouveras toutes les informations !"])},
  "awardleaders-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi participer ?"])},
  "awardleaders-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tuteur joue l’un des rôles les plus cruciaux dans le cadre du programme. Il doit pouvoir rencontrer les participants régulièrement et leur offrir du soutien lors qu’ils progressent dans le programme. Il aide le jeune à fixer ses objectifs, et représente une source d’encouragement et d’inspiration."])},
  "awardleaders-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toute personne motivée peut devenir tuteur et accompagner des jeunes dans le programme. Un tuteur peut être actif dans une unité ou agir comme freelance."])},
  "awardleaders-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment participer ?"])},
  "awardleaders-paragraph-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de commencer son activité de tuteur, il faut compléter la formation nécessaire. Si on souhaite encadrer les jeunes pendant les expéditions, il faut participer à une formation complémentaire ayant comme sujet ce volet spécifique."])},
  "awardleaders-paragraph-2-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux formations sont proposées au moins une fois par an en langue luxembourgeoise et anglaise."])},
  "awardleaders-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuteur"])},
  "awardleaders-paragraph-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un tuteur est un bénévole, qui encadre les participants tout au long du programme. En général, une unité compte au moins deux tuteurs pour répartir équitablement les tâches et assurer la continuité du programme. "])},
  "awardleaders-paragraph-3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un tuteur occupe le rôle de coordinateur qui est responsable de l’implémentation et de la gestion du programme du Mérite Jeunesse et qui est la personne de contact pour le bureau national."])},
  "awardleaders-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable Expédition"])},
  "awardleaders-paragraph-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le responsable d’expédition soutient ses participants à bien se préparer pour ce volet. Le responsable expédition prend contact avec le groupe de jeunes en expédition au moins une fois par jour et vérifie si les exigences horaires sont respectées et que chaque participant fait des efforts dans le cadre du travail en équipe."])},
  "awardleaders-paragraph-4-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une fois que l’expédition est réalisée, le responsable expédition doit débriefer l’équipe."])},
  "awardleaders-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
  "awardleaders-paragraph-5-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces formations visent à préparer les enseignants et toute autre personne travaillant avec des jeunes à bien guider et soutenir les participants à travers leurs programmes d'activités."])},
  "awardleaders-paragraph-5-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On peut faire partie d’une école ou organisation, qui fait déjà partie du Mérite, ou qui est intéressée de devenir une unité, mais il est aussi possible d’encadrer les participants en tant que freelance en encadrant les jeunes qui ne trouvent pas d’unité."])},
  "awardleaders-paragraph-5-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez des questions, n’hésitez pas à contacter le bureau national."])},
  "awardunits-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award Units"])},
  "awardunits-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dapibus odio arcu, eget ullamcorper arcu tempus eu. Phasellus  dignissim ante lorem, eget tristique mauris malesuada nec."])},
  "awardunits-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi participer ? "])},
  "awardunits-paragraph-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir une Unité du Mérite signifie que vous formez un partenariat avec le plus large mouvement international en faveur du développement de la jeunesse dans le monde. "])},
  "awardunits-paragraph-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La flexibilité unique du Mérite Jeunesse le rend idéal pour une adaptation et une intégration facile dans différentes cultures et sociétés. Les principes fondamentaux du Mérite Jeunesse restent les mêmes, mais les activités et la mise en œuvre continuent à évoluer et à s'adapter aux exigences changeantes de la société moderne et aux besoins changeants des jeunes."])},
  "awardunits-paragraph-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En raison de la flexibilité et de la durabilité du Mérite, le programme a énormément évolué au cours des 60 dernières années, et opère aujourd'hui dans plus de 130 pays sous différentes appellations."])},
  "awardunits-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avantages pour les unités :"])},
  "awardunits-benefits-list-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez intégrer dans le cadre du programme les activités péri- et parascolaires que votre établissement ou organisation offre déjà aux jeunes"])},
  "awardunits-benefits-list-1-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le partenariat vous offre l’opportunité de soutenir le développement personnel de jeunes dans le cadre d’un programme mondial, qui s’est avéré dans au fil des ans, avec des résultats mesurables et une renommée internationale."])},
  "awardunits-benefits-list-1-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous offrez aux jeunes la possibilité de se fixer des objectifs éducatifs qui sont basés sur leurs intérêts et leurs habiletés, et de les encourager ainsi à utiliser leur temps libre de manière enrichissante"])},
  "awardunits-benefits-list-1-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous encouragez le développement d’un sens de responsabilité personnelle et sociale auprès des jeunes"])},
  "awardunits-benefits-list-1-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par votre engagement pour les jeunes vous développerez vos compétences organisationnelles et professionnelles"])},
  "awardunits-benefits-list-1-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous favoriserez l’échange de savoirs et de savoir-faire entre les tuteurs de votre institution et ceux d’autres établissements et organisations"])},
  "awardunits-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment participer ?"])},
  "awardunits-how-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous êtes intéressé d’établir de créer une unité, il y a certaines démarches à compléter :"])},
  "awardunits-how-list-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors d’une rencontre avec les collaborateurs du bureau national, ils clarifient les détails avec vous et le personnel intéressé"])},
  "awardunits-how-list-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins deux personnes doivent suivre la formation tuteur"])},
  "awardunits-how-list-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un accord de partenariat doit être signé"])},
  "awardunits-how-list-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenant vous êtes officiellement une unité et pouvez offrir le programme aux jeunes"])},
  "awardunits-how-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y en n’a pas plus. Si vous intéressé, n’hésitez pas à nous contacter au"])},
  "supportus-header-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous soutenir"])},
  "supportus-header-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite Jeunesse soutient la jeunesse et nous avons besoin de votre aide pour pouvoir réaliser des projets et aider les jeunes à trouver leur sens, leur passion et leur place dans le monde"])},
  "supportus-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous soutenir"])},
  "supportus-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout ne s’apprend pas dans la salle de classe. Les jeunes ont besoin des expériences en dehors de l’école pour devenir des personnes engagées, responsables et satisfaites au sein de notre société."])},
  "supportus-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite Jeunesse encourage les jeunes en leur offrant un programme individuel et varié. Notre ambition est d’offrir à chaque jeune entre 14 et 24 ans la possibilité de participer auprès du Mérite Jeunesse. Ce but ne peut être réalisé qu’avec votre aide."])},
  "supportus-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peu importe le montant de votre don, chaque don marque une différence pour les jeunes participants. Ce n’est qu’avec votre aide et soutien, que les jeunes peuvent être préparés aux défis d’un monde moderne et changeant."])},
  "supportus-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez faire votre don par Digicash ou par virement sur le compte suivant :"])},
  "supportus-heading-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virement"])},
  "supportus-label-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la somme de vos dons est égale ou supérieure à 120 €, votre don est fiscalement déductible. Pour chaque don vous recevrez une attestation fiscale de notre part."])},
  "supportus-label-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agréée et conventionné par"])},
  "supportus-label-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec le généreux support de"])},
  "faq-description-text-1-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please keep in mind that the Award is a marathon and not a sprint. In circumstances like these, some activities need to be postponed and other activities can be done from home."])},
  "faq-description-text-2-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es-tu un/ participant/e ? Donc tu t’as peut-être déjà posées les questions suivantes !"])},
  "faq-description-text-3-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce que votre enfant aimerait faire le Mérite Jeunesse ? Donc vous vous avez peut-être déjà posées les questions suivantes !"])},
  "faq-description-text-4-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es-tu un/e tuteur/tutrice ? Donc tu t’as peut-être déjà posées les questions suivantes !"])},
  "faq-description-text-5-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous interessé à former une unité ? Donc vous vous avez peut-être déjà posées les questions suivantes !"])},
  "faq-description-text-6-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous supporter le Mérite Jeunesse ? Donc vous vous avez peut-être déjà posées les questions suivantes !"])},
  "faq-section-title-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COVID 19"])},
  "faq-section-title-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participants"])},
  "faq-section-title-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
  "faq-section-title-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuteurs"])},
  "faq-section-title-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités"])},
  "faq-section-title-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsors and Supporters"])},
  "faq-button-text-close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacher toutes les questions"])},
  "faq-button-text-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher toutes les questions"])},
  "faq-section-1-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How should I contact my Award Leader or Activity Coach while school and youth organizations are closed ?"])},
  "faq-section-1-1-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may have an email address or contact number of your Award Leader or Activity Coach you can use to call or send a text. If not, please ask the National Office to put you in touch with your Award Leader."])},
  "faq-section-1-1-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You might also consider setting up a messaging group on social media with other Award participants, so you can share ideas, motivate each other, and stay in touch during this time."])},
  "faq-section-1-1-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please keep in mind that every physical contact should be avoided as far as possible."])},
  "faq-section-1-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I cannot continue my physical activity because the club has closed and/or my team’s training sessions are cancelled. How should I proceed to continue with my Physical section ?"])},
  "faq-section-1-2-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please remember to continue your physical activity only if you are healthy."])},
  "faq-section-1-2-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your regular activity is not on now, there are many ways you can continue maintaining relevant strength and fitness. Whilst mass gatherings must be avoided, you could consider walking or running in your local area – or even doing a workout (or practicing your sport) in your garden. Apps like Strava or Google Fit can help you monitor your activities and provide evidence for your Assessor."])},
  "faq-section-1-2-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are also lots of fitness sessions and classes that can be followed online (YouTube) and done within your home; from Pilates to learning dance routines, boot camps to HIIT workouts. "])},
  "faq-section-1-2-description-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No matter which option is most suitable for you, please talk to your Award Leader in advance, ask for his/her approval, and clarify how you will document your activity."])},
  "faq-section-1-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I continue with my Voluntary Service section ?"])},
  "faq-section-1-3-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many possibilities to continue your Voluntary Service section depending on your activity. You might ask your Award Leader or Activity Coach, if there are things you can do from home, like creating a social media campaign or preparing administrative paperwork."])},
  "faq-section-1-3-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this option is not suitable for your activity, you might consider changing your activity. During the pandemic, you can offer your help and, for example, offer grocery shopping to people at risk. This clearly counts as activity for your Voluntary Service section."])},
  "faq-section-1-3-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please speak to your Award Leader about these options. If there is no other possibility, you can pause your activity. Please keep in mind that a pause should be the very last option. Before taking a final decision, talk with your Award Leader about every possibility."])},
  "faq-section-1-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My neighbor is self-isolating; can I help do their shopping for my Voluntary Service section ?"])},
  "faq-section-1-4-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you cannot continue your usual activity, this clearly is a great option and a sign of solidarity. "])},
  "faq-section-1-4-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If this is an option for you now, please keep the sanitary rules and recommendations of the government in mind. Before changing your activity, please seek approval from your Award Leader."])},
  "faq-section-1-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I continue my Skills activity from home ?"])},
  "faq-section-1-5-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If your usual activity for this section is cancelled, you can consider what you can do from home for this section. If you are playing an instrument or singing, you might intensify your practicing at home. The most common activities for this section can easily be done from home. Ask your Award Leader or Activity Coach, for suitable activities and possible alternatives. You might also consider looking for online tutorials or online learning classes. If this is not an option for you, you can do some research about your project. "])},
  "faq-section-1-5-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before taking a final decision, please speak to your Award Leader or Activity Coach to seek their approval and to fix a suitable way of recording your progress."])},
  "faq-section-1-6-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I wanted to start my Skills section now. Which possibilities do I have ?"])},
  "faq-section-1-6-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For this section, there are many possible activities, which can be done from home. Wouldn’t it be nice to learn how to cook or to bake ? Or how to sew ? If you are playing an instrument, you can practice at home. You can also consider learning a new language or learning how to draw/paint. You can also consider taking an online course on a certain subject."])},
  "faq-section-1-6-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A complete listing of every possible activity for the Skills section is available under this link: https://merite.jeunesse.lu/sites/default/files/pages/files/DofE-programme-ideas.pdf"])},
  "faq-section-1-6-description-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before starting your activity, please speak to your Award Leader and ask for approval."])},
  "faq-section-1-7-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Adventurous Journey is cancelled. What should I do now ?"])},
  "faq-section-1-7-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you planned an Adventurous Journey for April, it has to be postponed. Most Adventurous Journeys will be organized later this year. The responsible Assessor will inform you as soon as possible."])},
  "faq-section-1-7-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are leaving your Award Unit, before you had the possibility to catch up on the Adventurous Journey, ask your Assessor, if you still can join the expedition. If this option is not suitable for you, you might consider taking part in the next national expedition. If not, please contact the National Office and together we will find a solution for your situation."])},
  "faq-section-1-8-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I registered for the national expedition. What should I do now ?"])},
  "faq-section-1-8-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registration is still valid, just as the registration for the preparation workshop planned for 28thMarch 2020. If the national expedition has to be postponed, we will inform you as soon as possible via email and the new date will be published on our website."])},
  "faq-section-1-8-description-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The preparation workshop will probably take place together with the practice expedition. More information will follow as soon as possible."])},
  "faq-section-1-9-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I had to cancel my Gold Residential Project. What should I do now ?"])},
  "faq-section-1-9-description-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you had planned a Gold Residential Project for the upcoming weeks, it probably has been cancelled. Your planned project might be postponed to a later date and you can catch up on it as soon as the situation has calmed down. Try to prepare as much as possible from home. Please speak with your Award Leader or the person responsible of the project about your possibilities. "])},
  "faq-participants-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où puis-je m’inscrire ?"])},
  "faq-participants-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu t’inscris chez ton tuteur dans ton unité. Tu trouves une liste de nos unités ici."])},
  "faq-participants-8-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’aimerais faire le Mérite, mais ni mon école, ni mon organisation ne figure parmi les unités existantes."])},
  "faq-participants-8-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si ni ton école, ni ton organisation ne figure sur la liste des unités, tu peux contacter le bureau national. Les collaborateurs t’aideront"])},
  "faq-participants-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suis-j ’obligé de faire tous les volets simultanément ?"])},
  "faq-participants-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non. Tu peux faire un volet après l’autre."])},
  "faq-participants-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combien la participation coûte-t-elle ? "])},
  "faq-participants-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription coûte 5 € par niveau. Si tu décides de commencer le programme en tant qu’entrant direct au niveau Argent, les frais s’élèvent à 10 €. Si tu décides de commencer le programme en tant qu’entrant direct au niveau Or, les frais s’élèvent à 15 €. Ces frais sont fixés par le bureau national et valables pour chaque participant."])},
  "faq-participants-3-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines unités facturent encore des frais supplémentaires pour l’encadrement et d’autres unités payent les frais d’inscriptions pour leurs participants. Ainsi, il se peut que les frais de participation varient entre les unités."])},
  "faq-participants-3-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les frais d’inscription peuvent être couverts par l’Office Social. Il est possible que l’affiliation auprès de certains clubs engendre des frais supplémentaires pour les volets sport et talent. L’expédition peut être aussi engendrer des frais."])},
  "faq-participants-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon unité n’organise pas d’expédition. Où puis-je m’inscrire pour l’expédition nationale ?"])},
  "faq-participants-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le centre de ressources tu trouveras toutes les fiches d’inscription nécessaires. Après les avoir complétées, il faut les remettre à ton tuteur. Il les transmet au bureau national."])},
  "faq-participants-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis un scout actif. Est-ce qu’il est vraiment nécessaire de faire un atelier de préparation dans mon cas ?"])},
  "faq-participants-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non. Néanmoins nous recommandons de se préparer ensemble avec son groupe pour des raisons de l’esprit d’équipe."])},
  "faq-participants-6-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce qu’il faut faire un atelier et une expédition de préparation aussi au niveaux Argent et Or, si j’ai complété le niveau Bronze ?"])},
  "faq-participants-6-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu ne changes pas le mode de déplacement, il n’est pas nécessaire de refaire l’atelier. Si tu as fait une pause plus longue entre les différents niveaux, nous te recommandons de refaire l’atelier. Une expédition de préparation est obligatoire à chaque niveau. Mais pendant la pandémie autour du Covid-19, il y en quelques exceptions pour un temps limité."])},
  "faq-participants-7-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puis-je faire mon projet résidentiel Or avec mes copains ?"])},
  "faq-participants-7-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le projet résidentiel doit se dérouler dans un environnement étranger avec des personnes inconnues. Si un de tes copains aimerait participer au même projet, il faut veiller à ce que vous soyez dans de groupes différents."])},
  "faq-parents-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon enfant aimerait participer au programme du Mérite. Pourquoi devrais-je l’y encourager ?"])},
  "faq-parents-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite favorise le développement personnel des jeunes. Il s’agit d’un programme complétement individuel, qui peut être composé selon les intérêts du participant. Lors des différents volets, le jeune doit quitter sa zone de confort et ainsi apprendre à mieux se connaître tout en accumulant des expériences de vie importantes."])},
  "faq-parents-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si mon enfant participe au programme du Mérite Jeunesse, est-ce qu’il a encore assez de temps pour l’école ?"])},
  "faq-parents-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’il fait les trois volets simultanément, il se peut que mon enfant n’aie plus le temps pour faire ses devoirs à domicile et pour préparer les devoirs en classe."])},
  "faq-parents-2-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme du Mérite Jeunesse est flexible et se laisse adapter aux besoins individuels de chaque participant. Votre enfant n’est pas obligé de faire tous les volets en même temps, mais il est aussi possible de faire un volet après l’autre. Si on a une fois une semaine, où on n’a pas de temps, il est possible de faire une pause pendant une semaine. Cette semaine peut être reprise à la fin du volet."])},
  "faq-parents-2-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par ailleurs il est important d’avoir une compensation pour l’école. Avec ce programme individuel et varié, les participants apprennent encore d’autres compétences, qui sont importantes pour leur vie plus tard."])},
  "faq-parents-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui encadre mon enfant ?"])},
  "faq-parents-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque participant est encadré par un tuteur formé. Souvent le tuteur est un enseignant ou un éducateur du SEPAS ou d’une maison des jeunes. Pour assurer le meilleur encadrement, chaque tuteur doit suivre une formation chez nous. Les personnes qui organisent les expéditions, doivent suivre une autre formation plus spécifique."])},
  "faq-parents-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce que mon enfant est assuré par le Mérite Jeunesse ?"])},
  "faq-parents-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui, dès que le participant a remis la fiche d’inscription dûment signée, le participant est assuré chez nous pour toutes ses activités. Si les activités montrent un risque extraordinaire, nous ne pouvons pas garantir une assurance."])},
  "faq-parents-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-il possible de changer une activité, si celle-ci ne convient pas au participant ?"])},
  "faq-parents-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est toujours possible qu’une activité choisie n’est pas comme on l’a estimé. Si ceci est le cas, il est autorisé de changer l’activité dans ce volet après s’être concerté avec son tuteur. Pourtant un changement doit toujours rester l’exception, parce que le but du programme est d’encourager la persévérance et l’endurance pour finir ses projets."])},
  "faq-leaders-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où dois-je m’inscrire pour devenir tuteur ?"])},
  "faq-leaders-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous offrons au moins une fois par an une formation tuteur en langue luxembourgeois et une en langue anglaise. Il faut s’inscrire au bureau national. Les personnes relevant de l’Education Nationale peuvent aussi s’inscrire auprès de l’IFEN."])},
  "faq-leaders-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce que je dois être actif dans une unité pour devenir tuteur ?"])},
  "faq-leaders-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non. Tu peux aussi t’engager comme tuteur freelance ou bien proposer à ton école/ organisation de devenir une unité."])},
  "faq-leaders-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis scout et j’aimerais organiser les expéditions dans mon unité. Suis-je obligé de faire la formation supplémentaire ?"])},
  "faq-leaders-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non. Mais tu peux faire la formation, afin d’avoir une meilleure compréhension de l’expédition chez le Mérite. Avant de planifier des expéditions au sein de ton unité, informe le bureau national de ton expérience chez les scouts."])},
  "faq-leaders-4-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi les inscriptions pour les ateliers de préparation et l’expédition nationale sont-elles faites par le biais du tuteur ?"])},
  "faq-leaders-4-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le tuteur gère les inscriptions pour l’atelier et l’expédition nationale, celui-ci est automatiquement informé que ses participants sont inscrits pour l’atelier et/ ou l’expédition nationale."])},
  "faq-leaders-5-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où est-ce que je trouve les fiches d’inscriptions et de déclaration ?"])},
  "faq-leaders-5-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chacun de nous documents est disponible au centre de ressources."])},
  "faq-units-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quels sont les avantages pour mon école ou organisation, si nous offrons le Mérite Jeunesse ?"])},
  "faq-units-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite a des avantages pour les jeunes participants, mais aussi pour les tuteurs."])},
  "faq-units-1-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de leur participation au programme, les jeunes apprennent à mieux se connaître et reçoivent un certificat reconnu dans le monde entier."])},
  "faq-units-1-3-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les tuteurs qui accompagnent les participants prennent un rôle de mentor, ce qui est une expérience captivante pour nos bénévoles, parce qu’ils font connaissance des jeunes d’une nouvelle manière très différente qu’à l’école."])},
  "faq-units-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A qui dois-je m’adresser pour devenir une unité ?"])},
  "faq-units-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous êtes intéresse de lancer une unité, écrivez-nous à merite(at)youth.lu La personne, responsable pour votre type d‘organisation, va vous contacter le plus vite possible pour expliquer les démarches nécessaires en détail."])},
  "faq-units-3-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est-ce que devenir une unité est lié à des frais ?"])},
  "faq-units-3-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non, devenir une unité n’engendre pas de frais. Néanmoins il faut prévoir, que certaines activités, comme par exemple la formation tuteur ou l’expédition, sont liées à des frais. Il y a aussi des unités qui couvrent les frais d’inscriptions de 5-15€ pour leurs participants."])},
  "faq-sponsors-1-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi est-ce que je dois soutenir le Mérite Jeunesse ?"])},
  "faq-sponsors-1-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite Jeunesse est un programme lors duquel les jeunes peuvent fixer leurs propres buts, quitter leur zone de confort et se dépasser soi-même. C’est une expérience qui forge le caractère. Pour garantir le meilleur encadrement, nous sommes dépendants de vos dons."])},
  "faq-sponsors-2-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qu’est-ce qui se passe avec mon don ?"])},
  "faq-sponsors-2-1-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous vous décidez pour un don, nous vous communiquons bien sûr, l’usage que nous ferons de votre argent."])},
  "faq-sponsors-2-2-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec les dons, nous finançons uniquement des activités telles que l’expédition nationale, l’encadrement des expéditions au niveau Or et l’organisation de la cérémonie de Remise du Mérite."])},
  "merite-description-paragraph-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Mérite Jeunesse Luxembourg fait partie du The Duke of Edinburgh’s International Award, qui est le leader mondial des distinctions d’excellence pour les jeunes et actif dans plus de 130 pays."])},
  "merite-description-paragraph-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme du Mérite Jeunesse est ouvert à toute personne âgée entre 14 et 24 ans, sans distinction de sexe, d’origine, de culture, de religion, de capacité physique et d’intérêts. Il s’agit d’un programme flexible, lors duquel on peut fixer ses buts personnels et les achever sans pression de temps."])},
  "merite-description-paragraph-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour participer, il faut être actif dans quatre différents domaines : faire un bénévolat, pratiquer un sport, apprendre un talent et participer à une expédition. Tu peux choisir tes activités selon tes propres intérêts et capacités. Tu n’es pas obligé de faire toutes les activités en même temps."])},
  "merite-description-paragraph-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le concept du Mérite est celui du défi personnel. Il offre au jeune un programme équilibré d’activités volontaires qui favorisent la découverte de soi et l’épanouissement du jeune, son indépendance, sa persévérance, son sens de responsabilité envers soi-même et son service pour la communauté."])},
  "merite-description-paragraph-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvre plus d’informations sur le International Award: www.intaward.org"])},
  "team-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "team-sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines"])},
  "team-job-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistante administrative"])},
  "team-job-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secrétaire générale"])},
  "team-job-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable communication et activités nationales"])},
  "team-job-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert Expéditions (Freelance)"])},
  "team-text-astrid-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion administrative des formations, des expéditions et des projets résidentiels Or"])},
  "team-text-astrid-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Online Record Book"])},
  "team-text-astrid-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soutien en gestion événementielle"])},
  "team-text-astrid-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable comptabilité et statistiques"])},
  "team-text-astrid-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable qualité et développement"])},
  "team-text-christine-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations avec le CA et le MENJE"])},
  "team-text-christine-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations avec les unités privées et internationales"])},
  "team-text-christine-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations avec le IAF et les sponsors"])},
  "team-text-christine-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable formations ENG"])},
  "team-text-christine-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable comptabilité et statistiques"])},
  "team-text-christine-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable qualité et développement"])},
  "team-text-christine-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable qualité et développement"])},
  "team-text-christine-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion événementielle"])},
  "team-text-jil-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relations avec les unités publiques (lycées publics, maisons des jeunes et internats)"])},
  "team-text-jil-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable formations LU"])},
  "team-text-jil-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des expéditions"])},
  "team-text-jil-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable communication"])},
  "team-text-jil-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion événementielle & Workshops"])},
  "team-text-ben-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation de la qualité et de la sécurité des expéditions déclarées par les unités"])},
  "team-text-ben-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expéditions au niveau Or :"])},
  "team-text-ben-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soutien dans la préparation"])},
  "team-text-ben-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervision de l'expédition"])},
  "team-text-ben-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil et soutien aux unités pour la réalisation de leurs expéditions"])},
  "merite-conseil-text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un conseil d’administration, présidé par S.A.R. le Prince Guillaume, dirige la fondation au Luxembourg."])},
  "merite-conseil-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les membres du conseil d’administration et leur rôle :"])},
  "team-timing-astrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du lundi au jeudi (seulement le matin)"])},
  "team-timing-christine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du mardi au vendredi"])},
  "team-timing-jil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi au Vendredi"])},
  "team-conseil-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S.A.R. Prince Guillaume"])},
  "team-conseil-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])},
  "team-conseil-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice-Président"])},
  "team-conseil-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trésorier"])},
  "team-conseil-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secrétaire"])},
  "team-conseil-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événements"])},
  "team-conseil-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication et Marketing"])},
  "team-conseil-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressources Humaines"])},
  "team-conseil-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring"])},
  "team-conseil-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualité et Développement"])},
  "team-conseil-11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ressources humaines"])},
  "team-conseil-12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets spéciaux"])},
  "team-conseil-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition et Formations"])},
  "form-orb-selectlang-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préférence de langue"])},
  "form-orb-selectlang-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
  "form-orb-selectlang-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourgeois"])},
  "form-orb-selecttime-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scéance"])},
  "form-orb-selecttime-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séance 1: 15:30-16:30 en langue luxembourgeoise"])},
  "form-orb-selecttime-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séance 2: 17:00-18:00 en langue anglaise"])},
  "form-exp-unitlist-none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité n'est pas dans la liste"])},
  "form-exp-food-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjeuner le samedi"])},
  "form-exp-food-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(les frais sont inclus dans les 50€)"])},
  "form-exp-food-2-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(frais sont à payer par le participant)"])},
  "form-exp-food-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "form-exp-food-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "form-exp-food-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végétarien ou végétalien"])},
  "form-exp-food-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "form-exp-food-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végétarien"])},
  "form-exp-food-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végétalien"])},
  "form-exp-food-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres allergies alimentaires?"])},
  "form-disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En m'inscrivant à l'évènement, j'autorise Mérite Jeunesse à utiliser les photos et/ou vidéos sur lesquelles je figure éventuellement et qui ont été prises/enregistrées dans le cadre de l'évènement. Les photos/vidéos peuvent être utilisées pour illustration des publications ou la promotion du programme sur les réseaux sociaux et pour les publications imprimées du Mérite Jeunesse pour une durée non définie."])},
  "form-template-unitnotlisted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre unité n'est-il pas répertorié?"])}
}